import {Injectable} from '@angular/core';
import {ApiService} from '../api/api.service';
import {StorageService} from '../storage/storage.service';
import {BroadcasterService} from "../broadcaster/broadcaster.service";
import Bugsnag from "@bugsnag/js";
import {User} from "../../models/user";


@Injectable({
  providedIn: 'root'
})
export class UserService {

  is_user_logged: boolean = false;


  constructor(
    private api: ApiService,
    private storageService: StorageService,
    private broadcaster: BroadcasterService,
  ) {

    this.is_user_logged_in().then(risp => {
      //console.log('risp1', risp);
      this.is_user_logged = risp;
      this.broadcaster.is_user_logged(risp);
    });


  }

  init() {
    return new Promise((resolve, reject) => {
      console.log('UserServiceInitialized()');
      this.is_user_logged_in().then(risp => {
        console.log('risp2', risp);
        this.is_user_logged = risp;

      });



        resolve(true);
    });
  }


  public login(credenziali: any): Promise<any> {
    return new Promise((resolve, reject) => {
        this.api.post('authenticate', credenziali)
          .subscribe((risposta: any) => {


            console.log('OK login');
            console.log('risposta', risposta);

            console.log('dati', risposta['data']);
            this.storageService.set('token', risposta['data']['token']);


            this.user().then(user => {
              this.storageService.set('user', user);
              this.is_user_logged = true;



              resolve(true);


            }).catch(err => {
              //errrore recupero utente, cancello ed esco
              this.storageService.remove('token');
              this.storageService.remove('user');
              alert('errore recupero utente');

              resolve(false);
            });


          }, (err: any) => {

            console.log('err', err);
            let status = err.status;

            let messaggi = [];

            if (status === 401) {
              //caso credenziali non valide
              messaggi = err.error.messages;

            } else if (status === 422) {
              //caso errore validator
              console.log('err.error.errors', err.error.errors);


              const messaggierrore = Object.keys(err.error.errors).map(it => err.error.errors[it]);
              console.log('messaggierrore', messaggierrore);

              /* */
              for (let messaggio of messaggierrore) {
                console.log('messaggio', messaggio);
                for (let msg of messaggio) {
                  console.log('msg', msg);
                  messaggi.push(msg);
                }
              }
              console.log('messaggi', messaggi);
            }


            reject(messaggi);

          });
      }
    );
  }

  public logout(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.api.get('logout')
        .subscribe((risposta: any) => {


          console.log('OK Logout');


          //il logout in app viene fatto in ogni caso
          this.storageService.remove('token');
          this.storageService.remove('user');
          this.is_user_logged = false;


          //Bugsnag.setUser(null);

          resolve(risposta);


        }, (err: any) => {

          //il logout in app viene fatto in ogni caso
          this.storageService.remove('token');
          this.storageService.remove('user');
          this.is_user_logged = false;


          //TODO gestione
          console.log('ERRORE LOGOUT()');
          console.log(err);

          reject(err);

        });
    });
  }


  public resetpassword(credenziali: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.api.post('reset_password', credenziali)
        .subscribe((risposta: any) => {

          console.log('risposta', risposta);

          if (risposta && risposta.data && risposta.data.messages) {
            resolve(risposta.data.messages);
          }


        }, (err: any) => {


          console.log('err', err);
          if (err && err.error && err.error.messages) {
            console.log('dentro!!');
            reject(err.error.messages);
          }

          console.log('ERRORE GENERICO reset passwordd');


        });
    });

  }


  async user(): Promise<any> {
    return new Promise((resolve, reject): any => {
      this.api.get('user')
        .subscribe((risposta: any) => {


          console.log('OK Utente');
          console.log('risposta', risposta);

          if (risposta) {
            let user:User = risposta.data;
            this.storageService.set('user', risposta.data);

            //ad ogni chiamata /user al sito setto l'utente su bugnsag
            //sono sicuro di farne almeno una per page load
            Bugsnag.setUser(user.id,user.email,user.nome + ' ' + user.cognome );


            resolve(risposta.data);
          }


        }, (err: any) => {


          console.log('ERRORE user()');
          console.log(err);

          resolve(false);

        });
    });
  }


  async local_user(): Promise<any> {
    console.log('local_user');
    return new Promise((resolve, reject): any => {
      let user = this.storageService.get('user')

      if (user) {
        // console.log('carico User da storage')
        resolve(user);

      }
      resolve(this.user());
    });
  }

  async user_ultime(): Promise<any> {
    return new Promise((resolve, reject): any => {
      this.api.get('registrazioni/ultime')
        .subscribe((risposta: any) => {

          if (risposta) {
            resolve(risposta.data);
          }

        }, (err: any) => {


          console.log('ERRORE user_ultime()');
          console.log(err);

          resolve(false);

        });
    });
  }

  async is_user_logged_in(): Promise<any> {
    return new Promise((resolve, reject): any => {
      let token = this.storageService.get('token')
      if (token) {
        resolve(true);
      }
      resolve(false);
    });
  }

  async get_user_sesso(): Promise<any> {
    return new Promise((resolve, reject): any => {
      let user = this.storageService.get('user')
      if (user) {
        resolve(user.sesso);
      }
      resolve(false);
    });
  }


  async is_chat_ai_abilitata() {
    return false;
  }

  async hoChiamato(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.api.get('hochiamato'  )
        .subscribe((risposta: any) => {

          console.log('risposta', risposta);
          resolve(risposta);



        }, (err: any) => {




          console.log('ERRORE GENERICO hochiamato');
          reject(err);


        });
    });
  }
}
