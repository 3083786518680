
// DO NOT EDIT. This is a generated file.
// tslint:disable

export const BUILD_INFO = {
  "name": "rivoluzionedimagrante-v3ng",
  "version": "3.0.32",
  "date": "2025-01-13T08:24:03.146Z",
  "branch": null,
  "rev": "58c1ff1441",
  "tag": null,
  "commitsSinceLastTag": null,
  "authorDate": null,
  "buildNumber": null
};
