// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {

  bugsnag_api : '4bbdf9f052e871adc0a2657876e516ff',
  bugsnag_stage : 'develop',

  google_analytics_track_id : 'XXX',


  production: false,

  webapp_url: 'https://webapp.rivoluzionedimagrante.it/',



  attivazione_url: 'https://webapp.rivoluzionedimagrante.it/step',
  rinnova_app_url: 'https://www.rivoluzionedimagrante.it/gotoproduct.php',
  resetpassword_url: 'https://webapp.rivoluzionedimagrante.it/password/reset',

  sito_fa : 'https://www.filosofialimentare.it/',

  //api_url: 'https://webapp.rivoluzionedimagrante.it/api/v2',

  api_url: 'https://rivoluzione.spaziodemo.org/api/v2',
  language_translation_url: 'https://rivoluzione.spaziodemo.org/api/v2/lingueapp?lang=',
  language_translation_suffix: ''+'&_='+Date.now(),


  // api_url: 'http://rivoluzione.test/api/v2',
  // language_translation_url: 'http://rivoluzione.test/api/v2/lingueapp?lang=',
  // language_translation_suffix: ''+'&_='+Date.now(),

  lab_famiglia_url: 'https://webapp.rivoluzionedimagrante.it/laboratorio-famiglia',




  default_language: 'it',
  possibile_languages: [
    {
      'code': 'it',
      'name': 'Italiano',
      'flag_code': 'it',
      'locale': 'it-IT',
    },
    {
      'code': 'en',
      'name': 'English',
      'flag_code': 'uk',
      'locale': 'en-GB',
    },
  ],

  //key_laravel => google_icon
  icone_news: {
    '': 'list',
    'audio': 'volume_up',
    'video': 'videocam',
    'culinaria': 'dinner_dining',
    'giornalistica': 'newspaper',
  },


  google_api_key: 'AIzaSyBVK1UU2g3Yf1bLlpHzuGGVdAN0GMWH9C4',
  lemme_chat_link: 'https://filosofiaalimentare.pbx.firstpoint.it:30501/callus/#LiveChat800340',
  lemme_whastapp_number: '+390362304161',
  lemme_whatsapp_url_prefix: 'https://wa.me/',


  lemme_cell1: '+393484703019',
  lemme_cell2: '+393382526874',


  misure_possibili: [
    'peso',
    'glicemia',
    'pressione_max',
    'pressione_min',
    'collo',
    'braccia',
    'vita',
    'fianchi',
    'cosce',
    'ginocchia'
  ],


};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
