import {Component, OnInit} from '@angular/core';
import {AppComponent} from "../../app.component";
import {User} from "../../models/user";
import {StorageService} from "../../services/storage/storage.service";
import {CommonService} from "../../services/common/common.service";
import {UserService} from "../../services/user/user.service";
import {Settings} from "../../models/settings";
import {GeneralService} from "../../services/general/general.service";


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  appSettings: Settings = new Settings();
  user: User;
  ultimi_valori: any;
  private is_user_logged: boolean = false;

  public chiamatastring = '';

  constructor(
    private appComponent: AppComponent,
    private storageService: StorageService,
    private commonService: CommonService,
    private userService: UserService,
    private generalService : GeneralService,
  ) {
    this.commonService.setPageTitle('dashboard');
    this.user = new User();

  }

  ngAfterViewInit() {

  }

  ngOnInit(): void {

 this.loadAppSettings();

    this.is_user_logged = this.userService.is_user_logged;
    if (this.is_user_logged) {
      this.loadUtente();
    }


  }

  loadAppSettings(){
    this.generalService.load_settings().then(risp=>{
      if(risp){
        this.appSettings = risp;
      }
    });

  }


  loadUtente() {

    this.userService.local_user().then(risp => {
      this.user = risp;
      if(this.user && this.user.chiamate) {
        this.chiamatastring = this.user.chiamate.numero + '/' + this.user.chiamate.gg_mese;
      }
    }, err => {

    });
  }

  openDialogTest() {
    this.commonService.alertError('test messaggio errore1');
  }

  hoChiamato() {
    this.userService.hoChiamato().then(risp => {
    console.log('hoChiamato', risp);
    if(risp.data ){
      this.commonService.alertSuccess(risp.data.messages);
    }
    },err=>{
      console.log('hoChiamatoERROR', err);
      this.commonService.alertError(err.error.messages);
    });
  }
}
