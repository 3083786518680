<div class="bottone-lemme-content " [class]="color">
  <div class="bottone-lemme">
    <div class="d-flex justify-content-between align-items-center">
      <div class="icona" *ngIf="icona || iconaimg">
        <mat-icon  *ngIf="tipo == 'mat'">{{icona}}</mat-icon>
        <i *ngIf="tipo == 'fab'" [class]="['icona-fa','fa-brands', 'fa-'+icona]"></i>


      </div>
      <div class="testo flex-grow-1">
        <h4 class="titolo">{{titolo}}</h4>
        <div class="sottotitolo">{{sottotitolo }}</div>
      </div>
    </div>
  </div>
</div>

