<p>test works! Ciao!</p>

<h4>API URL</h4>
<p>{{env.api_url}}</p>

<a (click)="apriModal('successo')">Apri modale successo</a>
<br>
<a (click)="apriModal('errore')">Apri modale errore</a>
<br>
<a (click)="apriModal()">Apri modale std</a>
<br>
 

<pre>
  {{deviceInfo|json}}
</pre>

<br><br>
<a (click)="inviaBugsnag()">Invia errore bugsnag</a>
<br>
<a (click)="inviaErroreJS()">Invia errore js vero</a>
<br><br>


<h3>buildinfo</h3>
<pre>{{buildInfo|json}}</pre>
<div class="quadrato"></div>
test PROD2!!
